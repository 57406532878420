.profileScreenPWA {
    width: calc(100% - 20px);
    max-width: 760px;
    margin: auto;
    padding-top:58px;
}

.profileScreenPWA .sideContent {
    width: 100%;
    max-width: none;
    margin: auto!important;
}

#actionBarBackButton {
  display: flex;
  align-items: center;
  color: #000;
  height: 100%;
  min-width: 40px;
  padding: 6px 0px 6px 6px;
  border-radius: 10px;
  background-color: transparent;
  border: none;
}

#actionBarBackButton #actionBarBackButtonIcon {
  height: 35px;
}

html[dir="rtl"] #actionBarBackButton {
  padding: 6px 6px 6px 0px;
}

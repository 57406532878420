#bootstrap-overrides {
  overflow-x: hidden;
  height: 100%;
}

#bootstrap-overrides .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem transparent;
}

/* General Start Order header styles */
#bootstrap-overrides .modalStep.startOrder .modal-header {
  margin: 0;
  padding: 0;
  height: 0;
}

#bootstrap-overrides .modalStep.startOrder .modal-header button {
  display: none;
}

/* General start order screen styles */
#bootstrap-overrides .modalStep.startOrder {
  min-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Start Order Screen Positioning */
#bootstrap-overrides .modalStep.startOrder div.modal-content {
  min-height: 480px;
  position: relative;
}

#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .modal-body-custom {
  flex-grow: 30;
  display: flex;
  flex-direction: column;
}

#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .modal-body-custom
  .modal-custom-body-title {
  flex-grow: 1;
  height: 57px;
  min-height: 57px;
  max-height: 57px;
  display: flex;
  align-items: center;
}

#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .modal-body-custom
  .modal-custom-body-title
  .title-style {
  font-size: 22px;
  width: 100%;
}

#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .modal-body-custom
  .modal-custom-body-inner-content {
  flex-grow: 2;
}

#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .modal-body-custom
  .modal-custom-body-footer {
  flex-grow: 1;
  height: 130px;
  min-height: 130px;
  max-height: 130px;
}

#bootstrap-overrides .modalStep.startOrder div.modal-content .show-map {
  margin-top: -70px;
}

.user-address-show-map {
  position: relative;
  bottom: 60px;
  top: -32px;
}

#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .modal-body-custom
  .startOrderScreenTittle {
  margin-bottom: 55px;
}
#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .modal-body-custom
  .startOrderScreenTittle
  p {
  margin: 0 auto;
  font-size: 22px !important;
  text-align: center;
}

#bootstrap-overrides .modalStep.startOrder div.modal-content .modal-body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

#bootstrap-overrides
  .modalStep.startOrder
  div.modal-content
  .selectRestaurantForm
  .modal-body {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  transform: initial;
}

#bootstrap-overrides .modalStep.startOrder div.modal-content .modal-footer {
  position: absolute;
  width: 100%;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#bootstrap-overrides .modalStep .phoneNumberScreen .modal-footer {
  position: absolute;
  bottom: 10px;
}

/* OrderTimeScreen */
#bootstrap-overrides .custom-input {
  height: 50px;
}

#bootstrap-overrides select.custom-input.form-control {
  cursor: pointer;
}

#bootstrap-overrides select.custom-input option.custom-option {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: var(--menu-items);
}

#bootstrap-overrides
  .modalStep
  .custom-input
  .input-group-prepend
  span.input-group-text {
  border: 1px solid var(--button-outline);
  border-right-width: 0;
  border-radius: 0px;
  color: var(--primary-color);
  background-color: var(--menu-items);
}

#bootstrap-overrides .modalStep select.custom-input.form-control {
  border-top: 1px solid var(--button-outline);
  border-right: 1px solid var(--button-outline);
  border-bottom: 1px solid var(--button-outline);
  border-left: 1px solid transparent;
  margin-left: -4px;
  border-radius: 0px;
  z-index: 2;
  background: transparent;
  -webkit-appearance: none;
  color: var(--primary-font);
}

#bootstrap-overrides select.custom-input {
  border-radius: 10px;
}

#bootstrap-overrides .modalStep .custom-append {
  border: 1px solid var(--primary-color);
  border-left-width: 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/* OrderTimeScreen */

#bootstrap-overrides .modalStep .modal-content {
  z-index: 1050 !important;
}

#bootstrap-overrides .modalStep .modal-content .modal-header {
  border-bottom: 0 !important;
  display: flex;
  flex-direction: row;
}

#bootstrap-overrides .modalStep .modal-content .modal-header .modal-title {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

#bootstrap-overrides .mealDealModal .modal-title {
  width: 100% !important;
}

#bootstrap-overrides .modalStep .modal-content .modal-header button.close {
  line-height: 36px;
  z-index: 10;
}

#bootstrap-overrides .modalStep .modal-content .modal-header button.close span {
  margin-left: 0;
  border: 1px solid #4e4e4e;
  border-radius: 100%;
  padding: 0 10px 5px 10px;
  background-color: rgba(250, 250, 250, 0.833);
}

#bootstrap-overrides .modalStep .modal-content .moda-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}

#bootstrap-overrides .modalStep .modal-content .modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0;
  border-top: none;
}

#bootstrap-overrides .modalStep input#phoneNumber.form-control,
input#smsCode.form-control,
input#yourName.form-control,
input#yourEmail.form-control {
  font-size: 1.4rem;
}
#bootstrap-overrides .modalStep input#yourName.form-control::placeholder,
input#phoneNumber.form-control::placeholder,
input#smsCode.form-control::placeholder,
input#yourEmail.form-control::placeholder {
  color: #acacac;
}

#bootstrap-overrides .modalStep .input-group-prepend span.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #efefef;
  border: 1px solid #ced4da;
  border-right: 0;
}

#bootstrap-overrides .form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  pointer-events: none;
}

#bootstrap-overrides
  .modalStep
  .input-group-append
  i.material-icons.md-24.md-dark {
  display: flex;
  align-items: center;
  padding: 0.35rem 0.35rem;
  color: rgb(0, 0, 0);
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #efefef;
  border: 1px solid #ced4da;
  border-left: 0;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#bootstrap-overrides .btn-success {
  color: var(--buttons-font);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  height: 40px;
  border-radius: 0px;
}

#bootstrap-overrides .btn-outline-success {
  color: var(--primary-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--button-outline);
}

#bootstrap-overrides .btn-outline-success:focus {
  box-shadow: none;
}

#bootstrap-overrides
  .modalStep
  #userCredentialsPrepender
  span.input-group-text {
  border: none;
}

#bootstrap-overrides .btn-outline-success {
  height: 50px;
  border-radius: 0px;
}

#bootstrap-overrides .timeWarningBtn {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

#bootstrap-overrides .timeWarningBtn:hover {
  background-color: var(--primary-color);
  color: var(--buttons-font);
}

#bootstrap-overrides .addressInputs {
  color: var(--inputs-font) !important;
  border-radius: 0px !important;
}

#bootstrap-overrides .addressInputs::placeholder {
  color: var(--placeholder-color) !important;
}

#bootstrap-overrides .promoInput::placeholder {
  color: var(--placeholder-color);
}

#phone::placeholder {
  opacity: 0.4;
}

li.productWizardNavItem {
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  flex-direction: row;
}

.nav-tabs.productWizardNavTabs {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.nav-tabs.productWizardNavTabs::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.productWizardNavItem.nav-item .nav-link {
  display: inline-block;
  cursor: pointer;
  border: none !important;
  text-align: center;
}

.productWizardNavTabs.nav.nav-tabs {
  border-bottom: 1px solid #dadada !important;
}

.productWizardNavItem.nav-item .nav-link {
  position: relative;
}

.productWizardNavItem.nav-item .nav-link.active {
  border-bottom: 2px solid var(--primary-color) !important;
  background-color: var(--menu-items);
}

.form-control:focus {
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

.mapContainer {
  max-width: 50%;
  margin-left: 0;
}

#bootstrapoverrides #phoneScreenBackBtn.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: transparent;
  border: none;
}

@media only screen and (max-width: 800px) {
  .modalStep .modal-content {
    max-width: 100%;
  }

  #bootstrap-overrides .modalStep.startOrder div.modal-content {
    max-width: 93%;
    margin: 0 17px 0 -10px;
  }

  .mapContainer {
    max-width: 47%;
    margin-left: 3%;
  }

  #bootstrap-overrides .modalStep.startOrder div.modal-content .show-map {
    position: relative;
    top: 100px !important;
  }

  .user-address-show-map {
    position: relative;
    bottom: 15px;
  }

  .or-title-style {
    position: relative;
    /*bottom: 35px;*/
  }

  .savedAddresses {
    height: 100px !important;
  }

  .savedAddressesInactive {
    height: 100px !important;
  }
}

@media only screen and (max-width: 767px) {
  #bootstrap-overrides .modalStep.startOrder {
    /*height: 100vh;*/
    height: 100vh;
    min-width: 98%;
    margin: 0;
  }

  #bootstrap-overrides .modalStep.startOrder div.modal-content {
    /*min-height: 540px!important;*/
    /*min-height: 98%;*/
    min-height: 100%;
    max-height: 100%;
    min-width: 98%;
    width: 98%;
    margin: auto;
    overflow: hidden;
    /*position: absolute!important;*/
    /*top: 0!important;*/
  }

  #bootstrap-overrides .modalStep.startOrder div.modal-content .modal-body {
    position: initial;
    width: auto;
    transform: none;
  }

  #bootstrap-overrides .modalStep.startOrder div.modal-content .modal-footer {
    position: initial;
    width: auto;
    transform: none;
  }

  .customer-name-mobile-style {
    width: 150px;
  }

  .start-order-body-header-title {
    width: 100%;
    /*margin: 20px 0 !important;*/
    font-size: 22px !important;
  }

  .start-order-body-header-title .saved-addresses .title-style {
    margin-top: 0 !important;
    font-size: 22px;
  }

  .startOrderSelectRestaurantContainer {
    max-width: 100%;
    margin: 0;
    overflow: hidden;
  }

  .mapContainer {
    padding: 0 !important;
    max-width: initial;
    margin-left: 0;
  }

  #bootstrap-overrides
    .modalStep.startOrder
    div.modal-content
    .modal-body-custom {
    margin-top: 0;
  }

  #bootstrap-overrides
    .modalStep.startOrder
    div.modal-content
    .modal-body-custom
    .startOrderScreenTittle {
    margin-top: 0px;
    margin-bottom: 30px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 0.2rem auto;
  }
}

@media (min-width: 1280px) {
  #bootstrap-overrides.modal-open .modal {
    overflow: hidden;
  }
}

@media only screen and (max-width: 320px) {
  #bootstrap-overrides .test {
    max-width: 160px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 700px) {
  #bootstrap-overrides .test {
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

#bootstrap-overrides .nav-link {
  color: var(--primary-font);
}

.dropdown-item:active {
  background-color: #d8d8d8;
}

#bootstrap-overrides li.orderHistoryListGroupItem {
  border-bottom: 1px solid lightgrey !important;
  margin-bottom: 0px !important;
  border-top: 0px;
  padding-left: 6px;
  background-color: var(--menu-items);
}

#bootstrap-overrides .img-thumbnail {
    padding: 0px;
    border-radius: 0rem;
    border: none;
    height: 125px !important;
    width: 125px !important;
}

#bootstrap-overrides .img-thumbnail-mobile {
  padding: 0px;
  border-radius: 0rem;
  border: none;
  height: 125px !important;
  width: 100% !important;
}

#bootstrap-overrides .modal-backdrop.show {
  opacity: 0.8 !important;
}

/* #bootstrap-overrides .orderDetailsContainer a.nav-link {
    color: var(--primary-color) !important;
} */

.btn {
  font-weight: 300;
}
h5 {
  font-weight: 300;
}

/* RTL Arabic Desktop */

html[dir="rtl"]
  #bootstrap-overrides
  .modalStep
  .custom-input
  .input-group-prepend
  span.input-group-text {
  border: 1px solid var(--button-outline);
  border-left-width: 0;
  border-radius: 0px;
  color: var(--primary-color);
  background-color: var(--menu-items);
}

html[dir="rtl"]
  #bootstrap-overrides
  .modalStep
  select.custom-input.form-control {
  border-top: 1px solid var(--button-outline);
  border-left: 1px solid var(--button-outline);
  border-bottom: 1px solid var(--button-outline);
  border-right: 1px solid transparent;
  margin-right: -4px;
  border-radius: 0px;
  z-index: 2;
  background: transparent;
  -webkit-appearance: none;
  color: var(--primary-font);
}

html[dir="rtl"] #bootstrap-overrides .modalStep .custom-append {
  border: 1px solid var(--primary-color);
  border-right-width: 0;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* OrderTimeScreen */

html[dir="rtl"]
  #bootstrap-overrides
  .modalStep
  .modal-content
  .modal-header
  button.close
  span {
  margin-right: 0;
  border: 1px solid #4e4e4e;
  border-radius: 100%;
  padding: 0 10px 5px 10px;
  background-color: rgba(250, 250, 250, 0.833);
}

html[dir="rtl"]
  #bootstrap-overrides
  .modalStep
  .input-group-prepend
  span.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #efefef;
  border: 1px solid #ced4da;
  border-left: 0;
}

html[dir="rtl"]
  #bootstrap-overrides
  .modalStep
  .input-group-append
  i.material-icons.md-24.md-dark {
  display: flex;
  align-items: center;
  padding: 0.35rem 0.35rem;
  color: rgb(0, 0, 0);
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #efefef;
  border: 1px solid #ced4da;
  border-right: 0;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

html[dir="rtl"] .mapContainer {
  max-width: 50%;
  margin-right: 0;
}

html[dir="rtl"] #bootstrap-overrides li.orderHistoryListGroupItem {
  border-bottom: 1px solid lightgrey !important;
  margin-bottom: 0px !important;
  border-top: 0px;
  padding-right: 6px;
  background-color: var(--menu-items);
}

html[dir="rtl"] body#bootstrap-overrides {
  text-align: right;
}

/* RTL Arabic Max Width 800px */

@media only screen and (max-width: 800px) {
  html[dir="rtl"] #bootstrap-overrides .modalStep.startOrder div.modal-content {
    margin: 0 17px;
  }

  html[dir="rtl"] .mapContainer {
    margin-left: 0;
    margin-right: 3%;
  }
}

/* RTL Arabic Max Width 767px */

@media only screen and (max-width: 767px) {
  html[dir="rtl"] .mapContainer {
    margin: 0 auto;
    max-width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  html[dir="rtl"] .offset-sm-1 {
    margin-right: 8.333333%;
  }
}

/* @media (min-width: 470px) and (max-width: 767px) {
  html[dir="rtl"] .orderFrom {
    display: flex;
  }

  html[dir="rtl"] .orderFromChangeLocationTxt {
    display: block;
  }
} */
